import "./paiement.scss";
import "./ant-style.scss";
import { Layout } from "antd";
import Step1 from "./step1";
import { useEffect, useState } from "react";
import Step2 from "./step2";
import Step3 from "./step3";
import Step5 from "./step5";
import Step4 from "./step4";
import Step0 from "./step0";
import StepSuccess from "./step-success";
import StepHeader from "./step-header";

const PaiementTicket = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [data, setData] = useState({
    initForm: {
      productCode: null,
      refTicket: null,
      numTicket: null,
      dateTicket: null,
      montantTicket: null,
    },
    ticketInfo: null,
  });

  useEffect(() => {
    console.log("......DATA : ", data);
  }, [data]);

  const goToNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const goToPrevious = () => {
    if (currentStep === 0) {
      window.location.href = "/";
    } else {
      setCurrentStep(currentStep > 0 ? currentStep - 1 : 0);
    }
  };

  const sCallback = (inputValues, others) => {
    const inputs = inputValues ?? data?.initForm;
    const othersValues = others ?? data?.ticketInfo;
    setData({
      initForm: {
        ...data?.initForm,
        ...inputs,
      },
      ticketInfo: {
        ...data?.ticketInfo,
        ...othersValues,
      },
    });

    goToNext();
  };

  const eCallback = (inputValues, message) => {
    const inputs = inputValues ?? data?.initForm;
    setErrorMessage(message);
    setData({
      initForm: {
        ...data?.initForm,
        ...inputs,
      },
    });
    setCurrentStep(-1);
  };

  const getStep = (step) => {
    switch (step) {
      case 0:
        return (
          <Step1
            title={"Saisissez les informations du ticket"}
            data={data}
            sCallback={sCallback}
            eCallback={eCallback}
          />
        );
      case 1:
        return (
          <Step2 title={"Félicitations !"} data={data} onSubmit={goToNext} />
        );
      case 2:
        return (
          <Step3
            title="Choisissez votre mode de paiement"
            data={data}
            sCallback={sCallback}
            eCallback={eCallback}
          />
        );
      case 3:
        return (
          <Step4
            title={"Entrez votre numéro mobile money"}
            data={data}
            sCallback={sCallback}
            eCallback={eCallback}
          />
        );
      case 4:
        return (
          <Step5
            title={"Code de confirmation"}
            data={data}
            sCallback={sCallback}
            eCallback={eCallback}
          />
        );
      case 5:
        return <StepSuccess title={"Code de confirmation"} data={data} />;
      default:
        return (
          <Step0
            title={errorMessage ?? "Désolé, votre ticket n’est pas gagnant."}
            message={
              "Veuillez vous assurer d’avoir renseigné les informations exactes ou contactez le service d’assistance"
            }
            goToPrevious={goToPrevious}
          />
        );
    }
  };

  return (
    <Layout>
      <div
        id="retours"
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginBottom: 0, //70
          marginTop: currentStep != 5 ? 80 : 120,
        }}
      >
        <div className="steps-block-w">
          {currentStep != 5 && (
            <StepHeader step={currentStep + 1} goToPrevious={goToPrevious} />
          )}
          <div>{getStep(currentStep)}</div>
        </div>
      </div>
    </Layout>
  );
};

export default PaiementTicket;
